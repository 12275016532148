import type { FC, ComponentProps } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import type { Value as PhoneNumberValue, Country as CountryCode } from 'react-phone-number-input';
import Button from 'components/commercetools-ui/atoms/button';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Radio from 'components/commercetools-ui/atoms/radio';
import Input from 'components/commercetools-ui/atoms/input';
import Overlay from 'components/commercetools-ui/atoms/overlay';
import IndividualIcon from 'components/icons/individual';
import EmployerRegIcon from 'components/icons/employer-reg';
import EmployerSponsoredIcon from 'components/icons/employer-sponsored';
import GroupBookingsIcon from 'components/icons/group-bookings';
import PasswordInput from 'components/commercetools-ui/atoms/input-password';
import type { ReCaptchaRef } from 'components/commercetools-ui/atoms/reCaptcha';
import ReCaptcha from 'components/commercetools-ui/atoms/reCaptcha';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCountries } from 'providers/countries';
import type { RegisterAccount, UpdateAccount } from 'frontastic/hooks/useAccount/types';
import Feedback from './feedback';
import SaveOrCancel from './save-or-cancel';
import Markdown from '../../markdown';
import useDiscardForm from '../hooks/useDiscardForm';
import type { Account, FieldErrors } from '../types';
import { useSearchParams, useRouter } from 'next/navigation';
import type { States } from '../../../../../helpers/utils/mapStates';
import mapStates from '../../../../../helpers/utils/mapStates';
import { useAccount } from 'frontastic';
import track from 'helpers/gtm';
import TagsActionType from 'helpers/gtm/actions/types/tagsActionType';
import scrollToError from 'helpers/utils/scrollToError';
import ActivationModal from 'components/commercetools-ui/organisms/authentication/activation-modal';
import Chip from 'components/commercetools-ui/atoms/chip';
import type { LinkReference } from 'types/reference';

export enum AccountFieldsFormType {
    REGISTER = 'register',
    UPDATE = 'update'
}

export interface AccountFieldsFormProps extends ComponentProps<'form'> {
    type?: AccountFieldsFormType;
    privacyPolicy?: string;
    account?: Account;
    formClassName?: string;
    containerClassName?: string;
    title?: string;
    setRedirectToLogin?: (val: boolean) => void;
    individualDescription2?: string;
    corporateQualificationRedirectLink?: LinkReference;
    employerSponsoredDescription?: string;
    groupBookingsDescription?: string;
    employerRegistrationDescription?: string;
    showAllTiles?: boolean;
    individualDescription?: string;
    businessDescription?: string;
}

const AccountFieldsForm: FC<AccountFieldsFormProps> = ({
    type,
    account,
    privacyPolicy,
    formClassName,
    containerClassName,
    title,
    individualDescription2,
    corporateQualificationRedirectLink,
    employerSponsoredDescription,
    groupBookingsDescription,
    employerRegistrationDescription,
    showAllTiles,
    setRedirectToLogin
}) => {
    const corporateQualificationsInitialState = useMemo(() => {
        return {
            active: false,
            link: corporateQualificationRedirectLink?.link,
            inNewWindow: corporateQualificationRedirectLink?.openInNewWindow ?? true
        };
    }, []);

    const [corporateQualificationsRedirectTimer, setCorporateQualificationsRedirectTimer] = useState(3);
    const [corporateQualifications, setCorporateQualifications] = useState(corporateQualificationsInitialState);
    useEffect(() => {
        if (corporateQualifications.active) {
            const interval = setInterval(() => {
                setCorporateQualificationsRedirectTimer(prevCount => prevCount - 1);
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [corporateQualifications.active]);

    useEffect(() => {
        if (corporateQualifications.active && corporateQualificationsRedirectTimer <= 0) {
            window.open(corporateQualifications.link, corporateQualifications.inNewWindow ? '_blank' : '_self');
            setCorporateQualifications({ ...corporateQualifications, active: false });
            setCorporateQualificationsRedirectTimer(3);
        }
    }, [
        corporateQualifications,
        corporateQualificationsInitialState,
        corporateQualificationsRedirectTimer,
        setCorporateQualifications,
        setCorporateQualificationsRedirectTimer
    ]);

    const { getOktaAccountByEmail, getCimContact, register, update } = useAccount();
    const { formatMessage } = useFormat({ name: 'common' });
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
    const { formatMessage: formatValidationMessage } = useFormat({ name: 'validation' });
    const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

    const { countries, countryCodes } = useCountries();
    const [states, setStates] = useState<States[]>();
    const [requiredState, setRequiredState] = useState(false);
    const [isOverlay, setIsOverlay] = useState(false);

    const phoneCountries = countryCodes as CountryCode[];

    const isUpdateForm = useMemo(() => type === AccountFieldsFormType.UPDATE, [type]);
    const defaultStateOption = formatMessage({ id: 'select.state', defaultMessage: 'Please select your state/province' });

    const { discardForm } = useDiscardForm();

    const searchParams = useSearchParams();
    const existing = searchParams.get('existing');
    const source = searchParams.get('source');

    const router = useRouter();

    const initialDataValues: Account = useMemo(() => {
        if (isUpdateForm && account) {
            return {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                phoneMobile: '',
                countryCode: 'GB',
                confirmPassword: '',
                companyCountry: 'GB',
                jobTitle: '',
                salutation: '',
                companyName: '',
                companyAddress1: '',
                companyAddress2: '',
                companyAddress3: '',
                companyCity: '',
                companyPostCode: '',
                salesforceFinanceContact: '',
                vatId: '',
                region: ''
            };
        }
        return {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            phoneMobile: '',
            countryCode: 'GB',
            confirmPassword: '',
            cimAccountId: '',
            cimContactId: '',
            isExisting: !!existing
        };
    }, [account]);

    const resetB2BValues: Account = useMemo(() => {
        return {
            companyName: undefined,
            companyAddress1: undefined,
            companyAddress2: undefined,
            companyAddress3: undefined,
            companyCountry: undefined,
            companyCity: undefined,
            companyPostCode: undefined,
            accountPhone: undefined,
            salesforceFinanceContact: undefined,
            vatId: undefined,
            region: undefined
        };
    }, []);

    const [data, setData] = useState(initialDataValues);
    const [existingData, setExistingData] = useState(initialDataValues);
    const [reCaptchaReady, setReCaptchaReady] = useState(false);

    const [showCompanyFields, setShowCompanyFields] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [phoneValue, setPhoneValue] = useState<PhoneNumberValue>();
    const [phoneCountryValue, setPhoneCountryValue] = useState<PhoneNumberValue>();

    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState<FieldErrors>();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const recaptchaRef = useRef<ReCaptchaRef>(null);
    const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);
    const handleUpdateSubmit = async (data: RegisterAccount | UpdateAccount) => {
        //processing starts
        setLoading(true);

        try {
            const payload = data as RegisterAccount;
            const response = await update(payload);

            if ('errors' in response) {
                setFormErrors(response.errors);
            } else if ('errorSummary' in response) {
                setError(response.errorSummary as string);

                setFormErrors(undefined);
            } else if (!response.accountId) {
                setError(formatErrorMessage({ id: 'account.update.fail', defaultMessage: "Sorry. We couldn't update your account.." }));
                setSuccess('');
            } else {
                setFormErrors(undefined);
                setLoading(false);
                router.push('/account#payment');
            }
        } catch (err) {
            setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
            setSuccess('');
        }
        //processing ends
        setLoading(false);
    };

    const handleSubmit = async (data: RegisterAccount | UpdateAccount) => {
        //processing starts
        setLoading(true);

        try {
            const payload = data as RegisterAccount;
            const response = await register(payload);

            if ('errors' in response) {
                setFormErrors(response.errors);
            } else if ('globalErrors' in response) {
                setError(response.globalErrors as string);
                setFormErrors(undefined);
            } else if ('isStaged' in response) {
                setIsActivationModalOpen(true);
            } else if (!response.accountId) {
                setError(formatErrorMessage({ id: 'account.create.fail', defaultMessage: "Sorry. We couldn't create your account.." }));
                setSuccess('');
                setFormErrors(undefined);
            } else if (response.redirectToLogin && setRedirectToLogin) {
                setRedirectToLogin(true);
            } else {
                track({ type: TagsActionType.SIGN_UP, payload: { method: 'default' } });
                track({ type: TagsActionType.LOGIN, payload: { method: 'default' } });
            }
        } catch (err) {
            setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
            setSuccess('');
        }
        //processing ends
        setLoading(false);
        scrollToError();
    };

    const getOktaAccount = async (existing: string) => {
        setIsOverlay(true);
        try {
            const response = await getOktaAccountByEmail(existing);
            setExistingData({
                firstName: response.firstName,
                lastName: response.lastName,
                phoneMobile: response.phoneMobile,
                email: response.email,
                password: '',
                confirmPassword: '',
                isB2B: data.isB2B,
                isExisting: !!existing,
                countryCode: data.countryCode ?? 'GB'
            });
            setIsOverlay(false);
            if (response.errors) {
                setFormErrors(response.errors);
            }
        } catch (e) {
            setIsOverlay(false);
        }
    };

    const getContact = async (existing: string) => {
        setIsOverlay(true);
        try {
            const response = await getCimContact(existing);

            setExistingData({
                firstName: response.firstName,
                lastName: response.lastName,
                phoneMobile: response.phoneMobile,
                email: response.email,
                password: '',
                confirmPassword: '',
                isB2B: response.isB2B,
                countryCode: response.countryCode ?? 'GB',
                jobTitle: response.jobTitle,
                salutation: response.salutation,
                isExisting: !!existing
            });

            setShowCompanyFields(response.isB2B);
            if (response.errors) {
                setFormErrors(response.errors);
            }
            setIsOverlay(false);
        } catch (e) {
            setIsOverlay(false);
            setFormErrors(undefined);
        }
    };

    const titleOptions = useMemo(() => {
        return [
            {
                label: formatAccountMessage({ id: 'please.select', defaultMessage: 'Please Select' }),
                value: ''
            },
            { label: formatAccountMessage({ id: 'mr', defaultMessage: 'Mr.' }), value: 'Mr' },
            {
                label: formatAccountMessage({ id: 'mrs', defaultMessage: 'Mrs.' }),
                value: 'Mrs'
            },
            {
                label: formatAccountMessage({ id: 'dr', defaultMessage: 'Dr.' }),
                value: 'Dr'
            },
            {
                label: formatAccountMessage({ id: 'ms', defaultMessage: 'Ms.' }),
                value: 'Ms'
            },
            {
                label: formatAccountMessage({ id: 'miss', defaultMessage: 'Miss' }),
                value: 'Miss'
            }
        ];
    }, []);

    useEffect(() => {
        setData({ ...existingData });
    }, [existingData]);

    useEffect(() => {
        if (!!formErrors || !!error) {
            scrollToError();
        }
    }, [formErrors, error]);

    //handle text input change
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            if (e.target.name !== 'isB2B') {
                setData({ ...data, [e.target.name]: e.target.value });
            } else {
                const isb2b = e.target.value === 'true';
                setData({ ...data, [e.target.name]: isb2b, companyCountry: isb2b ? data.countryCode : undefined });
                setShowCompanyFields(isb2b);
            }
        },
        [data]
    );

    useEffect(() => {
        if (!showCompanyFields && !isUpdateForm) {
            setData({ ...data, ...resetB2BValues });
        }
    }, [showCompanyFields, isUpdateForm]);

    useEffect(() => {
        setShowForm((!isUpdateForm && typeof data.isB2B === 'boolean') || isUpdateForm);
    }, [isUpdateForm, data?.isB2B]);

    useEffect(() => {
        if (isUpdateForm && account) {
            setData({
                ...account
            });

            setPhoneValue(account.phoneMobile);
            setPhoneCountryValue(account.accountPhone);
            setShowCompanyFields(account.isB2B ?? false);
        }
    }, [account, isUpdateForm]);

    useEffect(() => {
        if (data?.companyCountry) {
            const mapedStates = mapStates(data?.companyCountry, defaultStateOption);
            setStates(mapedStates.states);
            setRequiredState(mapedStates.requred);
        }
    }, [data.companyCountry]);

    const handleSubmitForm = useCallback(
        async (e: React.FormEvent<HTMLFormElement> | React.FormEvent<Element>) => {
            e.preventDefault();

            if (!isUpdateForm) {
                const token = await recaptchaRef.current?.executeAsync();

                await handleSubmit({ ...data, reCaptchaToken: token ?? '' });
            } else {
                await handleUpdateSubmit(data);
            }
        },
        [isUpdateForm, data]
    );

    useEffect(() => {
        if (existing && source) {
            if (source === 'okta') {
                void getOktaAccount(existing);
            } else {
                void getContact(existing);
            }
        }
    }, [existing, source]);

    const displayField = (name: string) => {
        const fieldValue = existingData[name as keyof typeof existingData];
        const valueIsPlaceholder = fieldValue === '********';
        const fieldError = formErrors ? formErrors[name as keyof typeof formErrors] : null;
        const hasError = !valueIsPlaceholder && fieldError?.message;
        return !fieldValue || hasError;
    };

    return (
        <>
            {isOverlay && <Overlay hasLoader={true} />}
            <form
                data-cy={isUpdateForm ? 'update-account-form' : 'register-form'}
                onSubmit={async e => {
                    await handleSubmitForm(e);
                }}
                noValidate={true}
                className={formClassName}
            >
                {!isUpdateForm && !existingData.isB2B && (
                    <>
                        <p className="mt-15 font-heading text-18 font-bold">
                            {formatAccountMessage({ id: 'account.type', defaultMessage: 'Account Type' })}
                        </p>

                        <p className="mb-24 text-md">
                            {formatAccountMessage({
                                id: 'select.account.type',
                                defaultMessage: 'Select type of account you wish to create'
                            })}
                        </p>
                    </>
                )}

                <div className={containerClassName}>
                    <Feedback success={success} error={error} />
                    {!existingData.isB2B && (
                        <div className={`grid gap-16 ${!isUpdateForm ? 'md:grid-cols-2' : 'md:grid-cols-1'}`}>
                            <Radio
                                srOnly={true}
                                id="b2c"
                                name="isB2B"
                                value={isUpdateForm ? data?.isB2B?.toString() : 'false'}
                                onChange={handleChange}
                                disabled={isUpdateForm}
                                wrapperClassName={!isUpdateForm || !account?.isB2B ? 'block' : 'hidden'}
                                className="border-checkout-border flex h-full min-w-full cursor-pointer rounded-md border p-12 peer-checked:border-input-border"
                                required={true}
                            >
                                <div>
                                    <Chip type="INDIVIDUAL" />
                                    <div className={`flex items-center ${!isUpdateForm && 'border-checkout-border mb-10 border-b'}`}>
                                        <IndividualIcon className={`mr-6  ${isUpdateForm ? 'w-30' : 'w-24'}`} />
                                        <p className="ml-5 font-heading text-md font-bold leading-loose">
                                            {formatAccountMessage({ id: 'account.individual', defaultMessage: 'Self-funded' })}
                                        </p>
                                    </div>

                                    {individualDescription2 && <Markdown markdown={individualDescription2} className="text-sm" />}
                                </div>
                            </Radio>

                            {showAllTiles && (
                                <Radio
                                    srOnly={true}
                                    id="b2b"
                                    name="isB2B"
                                    value={isUpdateForm ? data?.isB2B?.toString() : 'true'}
                                    onChange={handleChange}
                                    disabled={isUpdateForm}
                                    wrapperClassName={!isUpdateForm || account?.isB2B ? 'block' : 'hidden'}
                                    className="border-checkout-border flex h-full cursor-pointer rounded-md border p-12 peer-checked:border-input-border"
                                    required={true}
                                >
                                    <div>
                                        <Chip type="INDIVIDUAL" />
                                        <div className={`flex items-center ${!isUpdateForm && 'border-checkout-border mb-10 border-b'}`}>
                                            <EmployerSponsoredIcon className={`mr-6  ${isUpdateForm ? 'w-34' : 'w-28'}`} />
                                            <p className="ml-5 font-heading text-md font-bold leading-loose">
                                                {formatAccountMessage({ id: 'account.sponsored', defaultMessage: 'Employer Sponsored' })}
                                            </p>
                                        </div>

                                        {employerSponsoredDescription && (
                                            <Markdown markdown={employerSponsoredDescription} className="text-sm" />
                                        )}
                                    </div>
                                </Radio>
                            )}

                            <Radio
                                srOnly={true}
                                id="b2b-employer-reg"
                                name="isB2B"
                                value={isUpdateForm ? data?.isB2B?.toString() : 'true'}
                                onChange={handleChange}
                                disabled={isUpdateForm}
                                wrapperClassName={!isUpdateForm || account?.isB2B ? 'block' : 'hidden'}
                                className="border-checkout-border flex h-full cursor-pointer  rounded-md border p-12 peer-checked:border-input-border"
                                required={true}
                            >
                                <div>
                                    <Chip type="BUSINESS" />
                                    <div className={`flex items-center ${!isUpdateForm && 'border-checkout-border mb-10 border-b'}`}>
                                        <EmployerRegIcon className={`mr-6  ${isUpdateForm ? 'w-30' : 'w-22'}`} />
                                        <p className="ml-5 font-heading text-md font-bold leading-loose">
                                            {formatAccountMessage({ id: 'account.employer', defaultMessage: 'Employer Registration' })}
                                        </p>
                                    </div>

                                    {employerRegistrationDescription && (
                                        <Markdown markdown={employerRegistrationDescription} className="text-sm" />
                                    )}
                                </div>
                            </Radio>

                            {showAllTiles &&
                                Boolean(
                                    typeof corporateQualificationRedirectLink?.link === 'string' &&
                                        corporateQualificationRedirectLink?.link.length
                                ) && (
                                    <Radio
                                        onClick={() => {
                                            setCorporateQualifications({ ...corporateQualifications, active: true });
                                        }}
                                        srOnly={true}
                                        id="b2b-group"
                                        name="isB2B"
                                        value={isUpdateForm ? data?.isB2B?.toString() : 'true'}
                                        onChange={handleChange}
                                        disabled={isUpdateForm}
                                        wrapperClassName={!isUpdateForm || account?.isB2B ? 'block' : 'hidden'}
                                        className="border-checkout-border flex h-full cursor-pointer rounded-md border p-12 peer-checked:border-input-border"
                                        required={true}
                                    >
                                        <p>
                                            <Chip type="BUSINESS" />
                                            <div
                                                className={`flex items-center ${!isUpdateForm && 'border-checkout-border mb-10 border-b'}`}
                                            >
                                                <GroupBookingsIcon className={`mr-6  ${isUpdateForm ? 'w-34' : 'w-28'}`} />
                                                <p className="ml-5 font-heading text-md font-bold leading-loose">
                                                    {formatAccountMessage({ id: 'account.group', defaultMessage: 'Group Bookings' })}
                                                </p>
                                            </div>

                                            {groupBookingsDescription && (
                                                <Markdown markdown={groupBookingsDescription} className="text-sm" />
                                            )}

                                            {corporateQualifications.active && (
                                                <p className="mx-5 mt-5 text-center font-heading text-md font-bold leading-loose">
                                                    {formatAccountMessage({
                                                        id: 'account.redirect',
                                                        defaultMessage: 'You are being redirected...'
                                                    })}{' '}
                                                    {corporateQualificationsRedirectTimer}
                                                </p>
                                            )}
                                        </p>
                                    </Radio>
                                )}
                        </div>
                    )}

                    {showForm && (
                        <>
                            {title && (
                                <Typography className="mb-15 mt-20 font-heading text-18 font-bold" as="p">
                                    {title}
                                </Typography>
                            )}

                            {displayField('salutation') && (
                                <Dropdown
                                    name="salutation"
                                    items={titleOptions}
                                    className="w-full"
                                    onChange={handleChange}
                                    label="Title"
                                    required
                                    data-cy="salutation"
                                    errorMessage={
                                        formErrors?.salutation?.message &&
                                        formatValidationMessage({
                                            id: formErrors.salutation.message,
                                            defaultMessage: 'Please add your title'
                                        })
                                    }
                                    value={data?.salutation ?? ''}
                                />
                            )}
                            {displayField('firstName') && (
                                <Input
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    autoComplete="firstName"
                                    required
                                    label={formatMessage({ id: 'firstName', defaultMessage: 'First Name' })}
                                    onChange={handleChange}
                                    data-cy="first-name"
                                    errorMessage={
                                        formErrors?.firstName?.message &&
                                        formatValidationMessage({
                                            id: formErrors.firstName.message,
                                            defaultMessage: 'Please add first name'
                                        })
                                    }
                                    value={data?.firstName ?? ''}
                                />
                            )}

                            {displayField('lastName') && (
                                <Input
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    autoComplete="lastName"
                                    required
                                    label={formatMessage({ id: 'lastName', defaultMessage: 'Last Name' })}
                                    onChange={handleChange}
                                    data-cy="last-name"
                                    errorMessage={
                                        formErrors?.lastName?.message &&
                                        formatValidationMessage({
                                            id: formErrors.lastName.message,
                                            defaultMessage: 'Please add last name'
                                        })
                                    }
                                    value={data?.lastName ?? ''}
                                />
                            )}

                            {displayField('jobTitle') && (
                                <Input
                                    id="jobTitle"
                                    name="jobTitle"
                                    type="text"
                                    required={showCompanyFields}
                                    label={formatMessage({ id: 'jobTitle', defaultMessage: 'Job Title' })}
                                    onChange={handleChange}
                                    data-cy="job-title"
                                    errorMessage={
                                        formErrors?.jobTitle?.message &&
                                        formatValidationMessage({
                                            id: formErrors.jobTitle.message,
                                            defaultMessage: 'Please add your job title'
                                        })
                                    }
                                    value={data?.jobTitle ?? ''}
                                />
                            )}

                            <p className="mb-5 mt-15 font-heading text-18 font-bold">
                                {formatAccountMessage({ id: 'contact.details', defaultMessage: 'Contact details' })}
                            </p>

                            <Dropdown
                                name="countryCode"
                                items={countries}
                                required
                                className="w-full"
                                label={formatMessage({ id: 'country', defaultMessage: 'Country' })}
                                onChange={handleChange}
                                data-cy="country-code"
                                value={data?.countryCode ?? 'GB'}
                                disabled={isUpdateForm}
                            />

                            {displayField('phoneMobile') && (
                                <div data-error={!!formErrors?.phoneMobile?.message}>
                                    <label htmlFor="phoneMobile" className="text-left text-14 font-label">
                                        {formatMessage({ id: 'phoneNumber', defaultMessage: 'Phone Number *' })}
                                    </label>

                                    <PhoneInput
                                        maxLength={22}
                                        name="phoneMobile"
                                        international
                                        countryCallingCodeEditable={false}
                                        className={formErrors?.phoneMobile?.message && 'error'}
                                        defaultCountry="GB"
                                        value={phoneValue}
                                        data-cy="phone-mobile"
                                        countries={phoneCountries}
                                        required
                                        onChange={e => {
                                            handleChange?.({
                                                target: { name: 'phoneMobile', value: e ?? '' }
                                            } as any);
                                            setPhoneValue(e);
                                        }}
                                    />

                                    {formErrors?.phoneMobile?.message && (
                                        <Typography className="mt-12 text-sm text-input-error" as="p">
                                            {formatValidationMessage({
                                                id: formErrors.phoneMobile.message,
                                                defaultMessage: 'Please add your phone number'
                                            })}
                                        </Typography>
                                    )}
                                </div>
                            )}

                            <p className="mb-5 mt-15 font-heading text-18 font-bold">
                                {formatAccountMessage({ id: 'access.details', defaultMessage: 'Accsess details' })}
                            </p>

                            {displayField('email') && (
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required={!isUpdateForm}
                                    label={formatMessage({ id: 'emailAddress', defaultMessage: 'Email Address' })}
                                    onChange={handleChange}
                                    data-cy="email-address"
                                    errorMessage={
                                        formErrors?.email?.message &&
                                        formatValidationMessage({
                                            id: formErrors.email.message,
                                            defaultMessage: 'Please add your email'
                                        })
                                    }
                                    disabled={isUpdateForm}
                                    value={data?.email ?? ''}
                                />
                            )}

                            {(existing === null || source === 'cimonly') && !isUpdateForm && (
                                <>
                                    <PasswordInput
                                        required
                                        id="password"
                                        name="password"
                                        autoComplete="current-password"
                                        label={formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
                                        onChange={handleChange}
                                        data-cy="password"
                                        errorMessage={
                                            formErrors?.password?.message &&
                                            formatValidationMessage({
                                                id: formErrors.password.message,
                                                defaultMessage: 'Please add your password'
                                            })
                                        }
                                    />

                                    <PasswordInput
                                        required
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        autoComplete="current-password"
                                        data-cy="confirm-password"
                                        label={formatAccountMessage({ id: 'password.confirm', defaultMessage: 'Confirm Password' })}
                                        onChange={handleChange}
                                        errorMessage={
                                            formErrors?.confirmPassword?.message &&
                                            formatValidationMessage({
                                                id: formErrors.confirmPassword.message,
                                                defaultMessage: 'Your passwords must match'
                                            })
                                        }
                                    />
                                </>
                            )}

                            {/* Company */}
                            {showCompanyFields && (
                                <>
                                    <p className="mb-5 mt-15 font-heading text-18 font-bold">
                                        {formatAccountMessage({ id: 'company.details', defaultMessage: 'Company details' })}
                                    </p>

                                    <Input
                                        id="companyName"
                                        name="companyName"
                                        type="text"
                                        required={!isUpdateForm}
                                        label={formatMessage({ id: 'companyName', defaultMessage: 'Company Name' })}
                                        onChange={handleChange}
                                        data-cy="company-name"
                                        errorMessage={
                                            formErrors?.companyName?.message &&
                                            formatValidationMessage({
                                                id: formErrors.companyName.message,
                                                defaultMessage: 'Please add your company name'
                                            })
                                        }
                                        disabled={isUpdateForm}
                                        value={data?.companyName ?? ''}
                                    />

                                    <Input
                                        id="companyAddress1"
                                        name="companyAddress1"
                                        type="text"
                                        required={!isUpdateForm}
                                        label={formatMessage({ id: 'companyAddress1', defaultMessage: 'Company address line 1' })}
                                        onChange={handleChange}
                                        data-cy="company-address-1"
                                        errorMessage={
                                            formErrors?.companyAddress1?.message &&
                                            formatValidationMessage({
                                                id: formErrors.companyAddress1.message,
                                                defaultMessage: 'Please add your company address 1'
                                            })
                                        }
                                        disabled={isUpdateForm}
                                        value={data?.companyAddress1 ?? ''}
                                    />

                                    <Input
                                        id="companyAddress2"
                                        name="companyAddress2"
                                        type="text"
                                        label={formatMessage({ id: 'companyAddress1', defaultMessage: 'Company address line 2' })}
                                        onChange={handleChange}
                                        data-cy="company-address-2"
                                        errorMessage={
                                            formErrors?.companyAddress2?.message &&
                                            formatValidationMessage({
                                                id: formErrors.companyAddress2.message,
                                                defaultMessage: 'This field must have a maximum 60 or less characters'
                                            })
                                        }
                                        disabled={isUpdateForm}
                                        value={data?.companyAddress2 ?? ''}
                                    />

                                    <Input
                                        id="companyAddress3"
                                        name="companyAddress3"
                                        type="text"
                                        label={formatMessage({ id: 'companyAddress3', defaultMessage: 'Company address line 3' })}
                                        onChange={handleChange}
                                        data-cy="company-address-3"
                                        errorMessage={
                                            formErrors?.companyAddress3?.message &&
                                            formatValidationMessage({
                                                id: formErrors.companyAddress3.message,
                                                defaultMessage: 'This field must have a maximum 60 or less characters'
                                            })
                                        }
                                        disabled={isUpdateForm}
                                        value={data?.companyAddress3 ?? ''}
                                    />

                                    <Input
                                        id="companyCity"
                                        name="companyCity"
                                        type="text"
                                        required={!isUpdateForm}
                                        label={formatMessage({ id: 'companyCity', defaultMessage: 'Town / City' })}
                                        onChange={handleChange}
                                        data-cy="company-city"
                                        errorMessage={
                                            formErrors?.companyCity?.message &&
                                            formatValidationMessage({
                                                id: formErrors.companyCity.message,
                                                defaultMessage: 'Please add your town/city'
                                            })
                                        }
                                        disabled={isUpdateForm}
                                        value={data?.companyCity ?? ''}
                                    />

                                    <Input
                                        id="companyPostCode"
                                        name="companyPostCode"
                                        type="text"
                                        required={true}
                                        label={formatMessage({ id: 'companyPostCode', defaultMessage: 'Company postcode/Eircode' })}
                                        onChange={handleChange}
                                        data-cy="company-postcode"
                                        errorMessage={
                                            formErrors?.companyPostCode?.message &&
                                            formatValidationMessage({
                                                id: formErrors.companyPostCode.message,
                                                defaultMessage: 'This field must have a maximum 10 or less characters'
                                            })
                                        }
                                        disabled={isUpdateForm}
                                        value={data?.companyPostCode ?? ''}
                                    />

                                    <Input
                                        id="salesforceFinanceContact"
                                        name="salesforceFinanceContact"
                                        type="email"
                                        label={formatMessage({ id: 'financeEmail', defaultMessage: 'Finance contact email address' })}
                                        onChange={handleChange}
                                        data-cy="sf-contact-email"
                                        required={!isUpdateForm}
                                        errorMessage={
                                            formErrors?.salesforceFinanceContact?.message &&
                                            formatValidationMessage({
                                                id: formErrors.salesforceFinanceContact.message,
                                                defaultMessage: 'Please add your email'
                                            })
                                        }
                                        disabled={isUpdateForm}
                                        value={data?.salesforceFinanceContact ?? ''}
                                    />

                                    <Dropdown
                                        name="companyCountry"
                                        label={formatMessage({ id: 'country', defaultMessage: 'Country' })}
                                        items={countries}
                                        className="w-full"
                                        required={!isUpdateForm}
                                        onChange={handleChange}
                                        data-cy="company-country"
                                        disabled={isUpdateForm}
                                        value={data?.companyCountry ?? 'GB'}
                                    />

                                    {states?.length ? (
                                        <Dropdown
                                            name="region"
                                            label={formatMessage({ id: 'stateOrProvince', defaultMessage: 'State/Province' })}
                                            items={states}
                                            className="w-full"
                                            required={!isUpdateForm && requiredState}
                                            onChange={handleChange}
                                            data-cy="company-region"
                                            disabled={isUpdateForm}
                                            value={data?.region ?? ''}
                                            errorMessage={
                                                formErrors?.region?.message &&
                                                formatValidationMessage({
                                                    id: formErrors.region.message,
                                                    defaultMessage: 'Please select your state/province'
                                                })
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}

                                    <div data-error={!!formErrors?.accountPhone?.message}>
                                        <label htmlFor="accountPhone" className="text-left text-14 font-label">
                                            {formatMessage({ id: 'phoneNumber', defaultMessage: 'Phone Number' })} {!isUpdateForm && '*'}
                                        </label>

                                        <PhoneInput
                                            maxLength={22}
                                            name="accountPhone"
                                            className={formErrors?.phoneMobile?.message && 'error'}
                                            international
                                            countryCallingCodeEditable={false}
                                            defaultCountry="GB"
                                            value={phoneCountryValue}
                                            data-cy="account-phone"
                                            required={!isUpdateForm}
                                            onChange={e => {
                                                handleChange?.({
                                                    target: { name: 'accountPhone', value: e ?? '' }
                                                } as any);
                                                setPhoneCountryValue(e);
                                            }}
                                            disabled={isUpdateForm}
                                        />
                                        {formErrors?.accountPhone?.message && (
                                            <Typography className="mt-12 text-sm text-input-error" as="p">
                                                {formatValidationMessage({
                                                    id: formErrors.accountPhone.message,
                                                    defaultMessage: 'Please add your phone number'
                                                })}
                                            </Typography>
                                        )}
                                    </div>
                                    <div className="flex items-end">
                                        {!isUpdateForm && (
                                            <p className={`'w-28' mr-6 ${formErrors?.vatId?.message ? 'mb-36' : 'mb-10'}`}>
                                                {data.companyCountry}
                                            </p>
                                        )}

                                        <div className="relative w-full">
                                            <Input
                                                id="vatId"
                                                name="vatId"
                                                type="text"
                                                className={`${isUpdateForm ? '' : 'mt-34'}`}
                                                customClass={`${isUpdateForm ? '' : 'absolute top[-34px] left-[-30px]'}`}
                                                data-cy="vat-id"
                                                label={formatMessage({ id: 'vatId', defaultMessage: 'VAT number' })}
                                                onChange={handleChange}
                                                errorMessage={
                                                    formErrors?.vatId?.message &&
                                                    formatValidationMessage({
                                                        id: formErrors.vatId.message,
                                                        defaultMessage: 'This field must have a maximum 20 or less characters'
                                                    })
                                                }
                                                disabled={isUpdateForm}
                                                value={data?.vatId ?? ''}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                            {!!privacyPolicy && (
                                <div className="pb-25 pt-15">
                                    <Markdown className="text-center text-md leading-loose" markdown={privacyPolicy} />
                                </div>
                            )}

                            {isUpdateForm ? (
                                <SaveOrCancel
                                    onCancel={discardForm}
                                    onSave={async e => {
                                        await handleSubmitForm(e);
                                    }}
                                    loading={loading}
                                    variant="save"
                                    className="mt-22"
                                />
                            ) : (
                                <Button size="full" type="submit" disabled={loading || !reCaptchaReady}>
                                    {formatAccountMessage({ id: 'account.register', defaultMessage: 'Register' })}
                                </Button>
                            )}
                        </>
                    )}

                    {!isUpdateForm && <ReCaptcha innerRef={recaptchaRef} errors={formErrors} setReadyState={setReCaptchaReady} />}
                </div>
            </form>

            {data.email && (
                <ActivationModal
                    isActivationModalOpen={isActivationModalOpen}
                    setIsActivationModalOpen={setIsActivationModalOpen}
                    email={data.email}
                />
            )}
        </>
    );
};

export default AccountFieldsForm;
